import React, { createContext, useState, useEffect, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { OrgContext } from "./OrgContext";
import I18n from "../common/I18n";
import { getFromToDate } from "../components/orders/helpers";
import AppConfig from "../config/AppConfig";
import { WarehouseContext } from "./WarehouseContext";

const SearchConfigContext = createContext({});

// const dafaultScreenData = {
//   BILLING: {
//     filteredData: {
//       accountCodes: [],
//       viewType: "byOrder",
//       fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
//       toDate: moment(),
//       driver_id: "",
//       invoiceAmountFilter: "BOTH",
//       search_order_token: "",
//       warehouse_id: "",
//     },
//     filter: I18n.t("invoices.ready_for_billing"),
//     paginationData: {},
//     innerTablePaginationData: {},
//     tableOptions: {},
//     innerTableOptions: {},
//     activeAccounts: [],
//   },
//   INVOICE: {
//     filteredData: {
//       fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
//       toDate: moment(),
//       accountCode: "",
//       wh_location_id: "",
//       viewType: "byOrder",
//       currentStatus: "PENDING",
//       sortBy: "none",
//       sortByType: "descend",
//       approvalDate: "billing_approval_date",
//     },
//     paginationData: {},
//     innerTablePaginationData: {},
//     tableOptions: {},
//     innerTableOptions: {},
//     searchBy: "Invoice",
//     searchedItem: "",
//     isSearching: false,
//     activeAccounts: [],
//   },
// };

const SearchConfigContextProvider = ({ children }) => {
  const { getSelectesWHs } =  useContext(WarehouseContext);
  const dafaultScreenData = {
    BILLING: {
      filteredData: {
        accountCodes: [],
        viewType: "byOrder",
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
        driver_id: "",
        invoiceAmountFilter: "BOTH",
        search_order_token: "",
        warehouse_id: getSelectesWHs(),
        approvalDate:moment(),
        accountCodes:[],
        los:"",
      },
      filter: I18n.t("invoices.ready_for_billing"),
      paginationData: {},
      innerTablePaginationData: {},
      tableOptions: {},
      innerTableOptions: {},
      activeAccounts: [],
      sortEntireTable: false,
    },
    INVOICE: {
      filteredData: {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
        accountCode: "",
        // wh_location_id: "",
        warehouse_ids: getSelectesWHs(),
        viewType: "byOrder",
        currentStatus: "PENDING",
        sortBy: "none",
        sortByType: "descend",
        approvalDate: "billing_approval_date",
        frequencyType: ""
      },
      paginationData: {},
      innerTablePaginationData: {},
      tableOptions: {},
      innerTableOptions: {},
      searchBy: "Invoice",
      searchedItem: "",
      isSearching: false,
      activeAccounts: [],
    },
  };
  const [screenData, setScreenData] = useState({
    ...dafaultScreenData
  });

  // const updateFilteredData = (screenFrom, updatedField, value) => {
  //   setScreenData((prevState) => {
  //     if (prevState[screenFrom]) {
  //       return {
  //         ...prevState,
  //         [screenFrom]: {
  //           ...prevState[screenFrom],
  //           [updatedField]: value,
  //         },
  //       };
  //     }
  //     return prevState;
  //   });
  // };

  const updateFilteredData = (screenFrom, dataToUpdate) => {
    setScreenData((prevState) => {
      if (prevState[screenFrom]) {
        return {
          ...prevState,
          [screenFrom]: {
            ...prevState[screenFrom],
            ...dataToUpdate,
          },
        };
      }
      return prevState;
    });
  };

  const contextValues = { screenData, updateFilteredData };

  return (
    <SearchConfigContext.Provider value={contextValues}>
      {children}
    </SearchConfigContext.Provider>
  );
};

export { SearchConfigContext, SearchConfigContextProvider };
